import React from "react";
import crystalImage from "../assets/crystal-image.webp";
import star from "../assets/star.svg";
import { Helmet } from "react-helmet";

const OurMission = () => {
  return (
    <div className="relative text-white  font-raleway min-h-screen max-w-screen-2xl  px-12 mx-auto ">
      <Helmet>
        <link rel="preload" as="image" href={crystalImage} />
      </Helmet>

      {/* Üst Başlıklar ve Sağ Metin (USERS ON TON) */}
      <div className="relative flex flex-row items-center justify-center z-10">
        {/* Sol Başlıklar */}
        <div className="space-y-2 text-center">
          <h1 className="text-5xl md:text-8xl lg:text-[130px] xl:text-[155px]  2xl:text-[175px] font-big-shoulders font-black tracking-wide">
            PLAY YOUR GAME
          </h1>
        </div>
      </div>

      {/* Sol Blok Big*/}
      <div className="hidden md:flex flex-row justify-between my-10 mx-10 z-10">
        <div>
          <p className="text-7xl font-big-shoulders font-extrabold">100K</p>
          <p className="text-lg font-thin">Wallet Connects</p>
        </div>
        <div>
          <p className="text-7xl font-big-shoulders font-extrabold">100K</p>
          <p className="text-lg font-thin">Monthly Users</p>
        </div>
      </div>

      {/* Sol Blok Mobil */}
      <div className="flex md:hidden relative mt-10 flex-row justify-between mx-5 z-10">
        <div>
          <p className="text-3xl font-big-shoulders font-extrabold">100K</p>
          <p className="text-sm font-thin">Wallet </p>
        </div>
        <div>
          <p className="text-3xl font-big-shoulders font-extrabold">100K</p>
          <p className="text-sm font-thin">Monthly Users</p>
        </div>
      </div>

      {/* Görsel - Arkaplan */}
      <div className="hidden md:inline md:absolute md:w-fit md:top-14 xl:-mt-20 mx-auto">
        <img
          src={crystalImage}
          alt="Crystal"
          className="mx-auto h-auto min-w-full  md:w-full  mix-blend-lighten opacity-80 "
        />
      </div>

      {/* Görsel - Arkaplan Mobil*/}
      <div className="block md:hidden mt-10 mx-auto scale-[2.5] ">
        <img
          src={crystalImage}
          alt="Crystal"
          className="mx-auto h-auto min-w-full  md:w-full  mix-blend-lighten opacity-80 "
        />
      </div>

      {/* Sağ Blok */}
      <div className="hidden md:flex relative  justify-end  mt-64  right-1/4 z-10 ">
        <div className="space-y-2">
          <div className="flex justify-end  w-full space-x-2  ">
            <img src={star} alt="Star" className="h-4 w-4" />
            <img src={star} alt="Star" className="h-4 w-4" />
          </div>
        </div>
      </div>

      {/* Sağ Blok Mobil*/}
      <div className="flex md:hidden relative  my-20  text-left z-10 ">
        <div className="space-y-2">
          <div className="bottom-8 md:mx-10 space-x-4 z-10  text-xs font-thin ">
            <a href="https://twitter.com" className="hover:text-gray-300">
              Twitter
            </a>
            <a href="https://linkedin.com" className="hover:text-gray-300">
              LinkedIn
            </a>

            <a href="https://instagram.com" className="hover:text-gray-300">
              Instagram
            </a>
          </div>
        </div>
      </div>

      {/* Alt Kısım */}
      <div className="hidden md:flex bottom-8 md:mx-10 space-x-4 z-10 xl:mt-20 text-sm font-thin ">
        <a href="https://twitter.com" className="hover:text-gray-300">
          Twitter
        </a>
        <a href="https://linkedin.com" className="hover:text-gray-300">
          LinkedIn
        </a>

        <a href="https://instagram.com" className="hover:text-gray-300">
          Instagram
        </a>
      </div>

      {/* Alt Kısım */}
    </div>
  );
};

export default OurMission;
