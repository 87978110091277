import React, { useState } from 'react';
import tonverse_logo from '../assets/tonverse_logo.webp';

function Header() {
  // State to manage mobile menu visibility
  const [isNavOpen, setIsNavOpen] = useState(false);
  // State to manage language dropdown visibility
  const [isLangOpen, setIsLangOpen] = useState(false);
  // State to manage selected language
  const [selectedLang, setSelectedLang] = useState('EN');

  const languages = [
    { code: 'EN', flag: '🇬🇧', name: 'English' },
    { code: 'ES', flag: '🇪🇸', name: 'Español' }
  ];

  const handleLanguageChange = (lang) => {
    setSelectedLang(lang);
    setIsLangOpen(false);
  };

  return (
    <header className="relative flex flex-col bg-white bg-opacity-5 backdrop-filter backdrop-blur-md backdrop-brightness-60 text-white p-1 md:px-10 px-4 justify-between items-center border-b-2 border-gray-400 border-opacity-20 w-full h-auto z-20">
      <div className="flex justify-between items-center w-full">
        {/* Logo */}
        <div className='flex items-center'>
          <img src={tonverse_logo} className='h-16 w-auto' alt="Tonverse Logo" />
        </div>

        {/* Orta Menü Öğeleri - Desktop */}
        <nav className="hidden md:flex space-x-8 text-sm">
          <a href="#overview" className="hover:text-gray-300 ">Overview</a>
          <a href="#media" className="hover:text-gray-300">Media</a>
          <a href="#news" className="hover:text-gray-300">News</a>
        </nav>

        <div className='flex items-center relative'>
          {/* Sağ Taraf - Dil Seçici */}
          <div className="flex items-center space-x-3 px-3 py-1 border border-gray-500 rounded-full text-white cursor-pointer" onClick={() => setIsLangOpen(!isLangOpen)}>
            <div>
              <span className="text-xs font-medium">{languages.find(lang => lang.code === selectedLang).flag}</span>
            </div>
            <span className="text-xs font-medium">{selectedLang}</span>
            <svg
              className="h-3 w-3 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>

          {/* Dil Seçici Açılır Menü */}
          {isLangOpen && (
            <div className="absolute top-12 right-0 w-28  bg-opacity-90 text-white rounded-md shadow-lg flex flex-col z-30">
              {languages.map((lang) => (
                <div 
                  key={lang.code} 
                  onClick={() => handleLanguageChange(lang.code)} 
                  className="flex items-center space-x-2 p-2 hover:bg-slate-700 cursor-pointer rounded-md"
                >
                  <span>{lang.flag}</span>
                  <span>{lang.name}</span>
                </div>
              ))}
            </div>
          )}

          {/* Hamburger Menu Button - Mobile */}
          <button
            className="block md:hidden ml-4 p-2 text-white focus:outline-none"
            onClick={() => setIsNavOpen(!isNavOpen)}
          >
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16m-7 6h7"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isNavOpen && (
        <nav className="flex flex-col items-start pl-6 space-y-2 mt-4 md:hidden w-full ">
          <a href="#overview" className="hover:text-gray-300">Overview</a>
          <a href="#media" className="hover:text-gray-300">Media</a>
          <a href="#news" className="hover:text-gray-300">News</a>
        </nav>
      )}
    </header>
  );
}

export default Header;
